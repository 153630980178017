import { store } from "../store";
import axios from "axios";
import { setToken, setAccessLevel, setConnected } from "store/authReducer";
import { Promise } from "q";

export default async function refreshToken() {
  try {
    const res = await axios.get("/api/auth/refresh");
    store.dispatch(setToken(res.data.accessToken));
    store.dispatch(setAccessLevel(res.data.accessLevel));
    return Promise.resolve(true);
  } catch (err) {
    if (err.response.status === 401) {
      return Promise.resolve(401);
    }
    return Promise.reject(err);
  }
}
