const Hebrew = {
  incorrectDetails: "שגיאה בפרטים",
  advancedOptions: "אפשרויות מתקדמות",
  notEnoughWords: "כמות המילים בביטוי אינה תקינה",
  noCoinsSelected: "לא נבחרו מטבעות",
  coins: "מטבעות",
  cryptoSearch: "חיפוש קריפטו",
  applications: "חיפוש אפליקציות",
  uploadAnImageFromTheAppStore: "העלה צילום מסך מחנות האפליקציות.",
  applicationsDescription:
    "התוכנה תזהה עבורך האם יש אפליקציות הקשורות לקריפטו (ארנקים וכדומה)",
  goodQualityImage: "אנא וודא כי התמונה באיכות טובה.",
  applicationsAndroid: `1. נכנסים לחנות האפליקציות (Google Play) \n
      2. לוחצים על תמונת הפרופיל של המשתמש שלכם בפינה העליונה של המסך. \n
      3. לוחצים על "ניהול האפליקציות והמכשיר" \n
      4. לוחצים על "ניהול" \n
      5. מבצעים צילומי מסך של האפליקציות המותקנות \n`,
  android: "אנדרואיד",
  iphone: "אייפון",
  applicationsIphone: `1. נכנסים לחנות האפליקציות (App Store) \n
      2. לוחצים על תמונת הפרופיל של המשתמש שלכם בפינה העליונה של המסך. \n
      3. גוללים קצת למטה ומבצעים צילומי מסך של כל האפליקציות המותקנות.
      `,
  filters: "פילטרים",
  isTokens: "טוקנים בשימוש",
  tron: "טרון",
  identify: "מזהה",
  type: "סוג",
  dogecoin: "דוג'קוין",
  hello: "שלום,",
  remove: "נמחק",
  search: "חיפוש",
  mail_title: "נתקלת בבעיה? רוצה לתת לנו משוב? צור איתנו קשר!",
  successfully_send: "הודעתך נשלחה",
  coin: "מטבע",
  time: "שעה",
  date: "תאריך",
  remove_message: "המשתמש הוסר בהצלחה",
  dont_have_history: "אין לך היסטורית חיפושים",
  plz_enter_id_for_remove: "הכנס בבקשה את הת.ז של המשתמש שאותו אתה רוצה להסיר",
  name_error: "שם לא יכול להכיל מספרים או אותיות באנגלית",
  more_info: "פירוט",
  hebrew_name: "שם מלא בעברית ללא מספרים",
  dont_show: "אל תציג הודעה זאת שוב",
  id: "ת.ז",
  enter_id: "הכנס ת.ז",
  error_id_phone: "ת.ז או טלפון לא תקינים",
  phone_error: "טלפון לא תקין",

  id_error: "ת.ז לא תקינה",
  plz_enter_id: "הכנס בבקשה את הת.ז של המשתמש שאותו אתה רוצה לערוך",
  move_main: "הבנתי",
  phoneNumber: "מס' טלפון",
  user_email: "כתובת מייל",
  mail: "מייל",
  body_message: "גוף ההודעה",
  send_mail: "שלח מייל",
  signin: "כניסה",
  confirm: "אימות",
  code: "קוד אימות",
  name: "שם מלא",
  unit: "יחידה",
  jobTitle: "תפקיד",
  create: "יצירת משתמשים",
  register: "יצירת משתמש חדש",
  users: "משתמשים קיימים",
  main: "ראשי",
  info: "מידע",
  seed: "מילים",
  incorrect_seed: "מילים לא תקינות",
  str: "טקסט",
  price: "מחיר",
  access_level: "רמת משתמש",
  seed_size: "בחר מספר מילים",
  password: "סיסמה - passphrase (אפשרי)",
  check: "בדיקה",
  logout: "התנתק",
  main_screen: "מסך ראשי",
  choose_word: "בחר מילה",
  search_word: "חפש מילה",
  search_by_text: "חיפוש לפי טקסט",
  enter_word: "הכנס טקסט",
  what_is_seed: "מה זה סיד?",
  balance: "יתרה",
  transaction: "העברות",
  history: "היסטוריה",
  missing_words: "חסרים פרטים",
  error: "חסרים פרטים",
  incorrect: "שגיאה בפרטים",
  has_details: "הפרטים כבר קיימים",
  bitcoin: "ביטקוין",
  ethereum: "אתריום",
  confirm_code: "קוד אימות נשלח בהצלחה",
  hold_on: "חכה!",
  exit_message: "אתה בטוח שאתה רוצה לצאת מהאפליקציה?",
  cancel: "ביטול",
  yes: "כן",
  update: "עדכן",
  update_message:
    "יצא עדכון לאפליקציה. על מנת להשתמש באפליקציה עליך להתקין אותה מחדש.",
  ok: "אישור",
  edit: "ערוך",
  edit_user: "עריכת משתמש",
  delete: "מחק",
  in: "כתובות מקבלות",
  get_info: "מידע:",
  nft_info: `הוא סוג מיוחד של פרוטוקול קריפטוגרפי, המייצג נכס ייחודי. בניגוד למטבעות מבוזרים כגון Bitcoin, NFT לא ניתן להחלפה באחר דומה לו ולכן מעצם טבעו אינו בר-חליפין.
    
    NFT מאפשר הוכחת מקוריותה של היצירה והבעלות עליה, אף שתחום האמנות הדיגיטלית מעצם טבעו מאפשר שכפול קל מהיר ומדויק של היצירה והפצתה ללא הרשאה ברשת האינטרנט
        `,
  tx_info: `העברה כספית (או מידע מסוג אחר) בין כתובת ארנק (של מטבע קריפטוגרפי) אחת לאחרת.
    לכל טרנזקציה יש מזהה ייחודי הנקרא TX ID או TX Hash שבאמצעותו אפשר לחפש מידע על אותה טרנזקציה בבלוקצ'יין.
    כל טרנזקציה מורכבת ממספר קלטים (כתובות ששלחו מטבעות) ומספר פלטים (כתובות שקיבלו מטבעות), כל אחד מהם בסכום שונה.
        
    דוגמה:
    בארנק מסוים ישנם 5 Bitcoin שמחולקים ל-2 כתובות, באחת מהן 3 Bitcoin ובשנייה 2.
    כאשר בעל הארנק יעביר לארנק אחר 4 Bitcoin הוא ישתמש בשתי הכתובות שברשותו כיוון שכל אחת מהן לבדה אינה מספיקה. במקרה זה יהיו לטרנזקציה 2 קלטים.
    כיוון שברשות השולח 5 Bitcoin והוא משלם רק 4, הוא יעביר את העודף, Bitcoin אחד, לכתובת אחרת שברשותו (בד"כ כתובת חדשה שהארנק שלו יוצר). במקרה זה יהיו 2 כתובות כפלט – כתובת של ארנק היעד שיקבל 4 Bitcoin והכתובת של השולח שתקבל קצת פחות מ-Bitcoin אחד.
    הסיבה שהעודף יהיה פחות מ-Bitcoin אחד היא שבכל טרנזקציה יש לשלם עמלה לכורים (אלו שמתחזקים את הבלוקצ'יין של Bitcoin), העמלה משולמת בשברירי Bitcoin (בדומה לאגורות מול השקל).`,
  pub_info: `מחרוזת ארוכה של אותיות ומספרים אשר מטרתה להוות אימות לבעלות על כתובת הארנק (ממפתח זה מייצרים את הכתובת). המפתח הציבורי והכתובת קשורים בצורה מתמטית באופן חד כיווני כך שניתן להגיע מהמפתח אל הכתובת (מפתח מסוים לעולם יהיה קשור לכתובת ספציפית), אך לא ניתן להגיע מהכתובת אל המפתח.
    מפתחות ציבוריים ב-Bitcoin מתחילות ב – 02, 03 ,04 ובתחיליות – x/y/z ולאחר מכן "pub".
    דוגמות למפתחות ציבוריים ב-Bitcoin:
    
    025918f4c2ec7bff521ae890ec3137a6c24e73a6c892cfc5d25bca318b79845ea3
    
    xpub6DSYTridGrheYW3HefNxybntJ8tWhrhQw1hFANMULTcQvdv9edTB6MGwJhU511hFZ4MpwLydmtLsNLewA5TQX8kpe3nrA2CmRuSrdscXxyh
    
    מפתחות ציבוריים ב-Ethereum מתחילים ב – 0x"" וארוכים  יותר מהפרטיים. דוגמות למפתח ב-Ethereum:
    
    0x03186872e6c5becc7d662004a0d01988965301fda9f20835f5bcf0647a20ad586e`,
  prv_info: `מחרוזת ארוכה של אותיות ומספרים המהווה את הקשר המתמטי בין ארנק למטבעות שמוחזקים בו (ממנו מייצרים מפתחות ציבוריים ומהם כתובות ש"מחזיקות" את יתרת המטבעות). המפתח הפרטי הוא סוג של סיסמת הגישה למטבעות, לכן למי שיש את המפתח הפרטי, יש גם את הגישה למטבעות והנכסים המקושרים לארנק.
    מפתחות פרטיים של Bitcoin יכולים להתחיל רק ב – 0 או 1, 1-9,
    ב0-9 וA-F,5H ,5J ,5K , K, S, 6P,  L
    ובתחיליות – x/y/z ולאחר מכן "prv".
    דוגמות למפתחות Bitcoin:
    
    Kwr8SDrK11ZSinbKw8jdcvQrXD1QCEKozRM2RVFkzjafcekQzP8X
    
    L517nqj87RM4vF2YjfQdHmMCKDkpYAviu7JBa2dPDVfeXBYMijkE
    
    S6c56bnXQiBjk9mqSYE7ykVQ7NzrRy
    
    xprv9yYFwFoG2xnPGLPbZS3nUJyV5a8jgz8ZCXMjNHnFPZKKqknL2MjLAoZPyLsX2SiUsWyab5gQ8Dbc2t7tdDFqnkwh63aBCJLuKLt9BPeSYPz
    
    zprvAWgYBBk7JR8GkC7Pytp2LdKXWSrnK5az7mkyKvr9onUq3MvwL9f3yn1XJrRvPxrvGQKohjxWFMnxfy42WdwNiWKo9gXnz247tyCECXJpyhx
    
    yprvALJHJuw1PJoGoKbnGH9s2nWwuYiJsaJ6JWCT6Qy91Fx6GqiMwpsL5P12rfErVaUKZg7sWcAHc2GxdhtShvsPb1X7w4G1ivm1vaizfeVbmHD
     
    מפתחות פרטיים בEthereum- מתחילים ב – 0x"". דוגמה למפתח ב-Ethereum:
    
    0xfbf687f190d9370727fa7e28558b6794019325d7c3747cacddaae179d4147053`,
  wallet_info: `הכתובת משמשת לשליחה או קבלה של עסקאות ברשת. הכתובת מוצגת כמחרוזת תווים משולבים של אותיות ומספרים. הארנק מקושר למפתחות אשר מעניקים גישה אל המטבעות הקריפטוגרפים. כתובות Bitcoin יכולות להתחיל אך ורק בתווים 3/1/bc1. דוגמה לכתובות Bitcoin:
    
    1EJ1U2eKSgZGSz6yVmksZFczAnK8F6MA7V
    
    bc1q4cc4xuyslvg6f4y7g6naewe942ne09d55y4dm2
    
    3JauUmvzLpFsLcch46dgRzVTXPEAMxU8BM
    
    כתובות Ethereum יתחילו רק ב- 0x, דוגמה לכתובת Ethereum:
    
    0xa8A3994114236c6f078A79D2615679cA05B52058`,
  wait: "שים לב",
  blockchain_info: `שרשרת המורכבת מבלוקים ושייכת למטבע ספציפי (ישנם סוגי מטבעות ונכסים, לדוגמה NFT, אשר נמצאים כולם על בלוקצ'יין יחיד כגון הבלוקצ'יין של המטבע Ethereum). שרשרת בלוקים זו שקולה ליומן חשבונאי כאשר כל בלוק הוא דף ביומן ומכיל את רישומי ההעברות שקרו ב-10 הדקות שקדמו לחתימתו.`,
  warnings: `1.	המידע באפליקציה רגיש ואין לעשות בו שימוש לרעה – אין להעביר את המידע לגורם אחר שאינו מורשה לצפות בו, אין לשמור את המידע בפלאפון ואין לבצע העברה של מטבעות וירטואליים.
    2.	המידע נשמר באפליקציה למשך 24 שעות על מנת שתוכל להיעזר בו בעת כתיבת דו"חות לאחר ביצוע הפעולה. לאחר מכן המידע יימחק ותיאלץ לבצע חיפוש חדש.
    `,
  out: "כתובות שולחות",
  mnemonic_info: `רשימת מילים באורך של 12/24/18 מתוך מילון עם מילים נבחרות, רשימת מילים זאת נקראת גם מילות השחזור לארנק. (לעיתים נקראת בטעות "סיד", למרות שמונח זה מתאר מחרוזת אחרת - המחרוזת המופקת ממילים אלו). 
    
    במילים אלה טמון הארנק כולו, מהן ניתן לייצר את כל המפתחות (פרטיים וציבוריים) והכתובות של אותו ארנק, ובכך להעניק גישה מלאה לארנק ואפשרות להעברת המטבעות ממנו. סדדר המילים חשוב ליצירת הארנק, הזנת המילים בסדר שגוי יגרום ליצירת ארנק אחר.`,
  pass_info: `מחרוזת לבחירת בעל הארנק (כאשר יוצר את הארנק) המשמשת כשכבת אבטחה אופציונאלית (סוג של סיסמה אישית) בנוסף למילות השחזור(Mnemonic).
    אם בעל הארנק אכן הוסיף סיסמה כזאת על מילות השחזור, ייווצר ארנק אחר לגמרי.
        `,
  address: "כתובת",
  value: "כמות",
  fee: "סכום",
  users_mess: "לא נמצאו משתמשים",
  from: "כתובת מקבלת",
  to: "כתובת שולחת",
  err: "שגיאה",
  err_from_server:
    "אירעה שגיאה, אנא נסה שנית מאוחר יותר. במידה והתקלה נמשכת פנה לתחום טכנולוגיות ביחידת הסייבר, להב 433",
  err_id: "ת.ז חייבת להיות 9 ספרות",
  successfully_created: "המשתמש נוצר בהצלחה!",
  successfully_created_message:
    "כעת המשתמש יכול להוריד את האפליקציה לטלפון שלו ולהתחבר",
  first_message: `ברוך הבא ל- Crypto-Scouter!
    כלי זה מיועד לשימוש בעת ביצוע חיפוש בזירה ומטרתו היא לאפשר קבלת מידע בזמן אמת על מטבעות קריפטוגרפיים.
    ביכלותך להזין מחרוזת טקסט הנמצאות בזירה גם אם אינך בטוח שהיא קשורה למטבעות קריפטוגרפיים. 
    התשובה שתתקבל תציין האם המחרוזת אכן קשורה למטבעות, ובמידה וכן, מהי מסמלת, את הכתובות הנגזרות ממנה ואת יתרת המטבעות והיסטוריית העסקאות שלהן.
    `,
  us: "האפליקציה פותחה על- ידי תחום טכנולוגיות סייבר ביחידת הסייבר הארצית- להב 433",
  address: "כתובת",
  transaction_id: "מזהה עסקאות (hash)",
  date: "תאריך",
  amount: "סכום",
  code_error: "קוד שגוי",
  cropImage: "חתוך תמונה",
  uploadFile: "בחר קובץ",
  completed: "הושלם",
  processing: "טוען",

  reports: "דוחות",
  entrancesReport: "כניסות",
  sameReport: "חיפושים זהים",
  usersReports: "משתמשים",
  searchsReports: "חיפושים",
  noResult: "לא נמצאו תוצאות",
  results: "תוצאות:",
  supportedCoin: "האפליקציה תומכת במטבעות: ביטקוין, אתריום, טרון ודוג'קוין",
  tokens: "טוקנים",
  tooManyRequests: "יותר מידי בקשות",
  login: "התחברות",
  menu: "תפריט",
  settings: "הגדרות",
  chooseService: "אנא בחר/י את השירות המבוקש",
  needHelp: "צריך/ה עזרה?",
  count_words: "כמות מילים: ",
  filterBy: "מיין לפי",
  all: "הכל",
  move_to_login: "עבור לדף התחברות",
  move_to_home: "למסך הבית",
  login_time_over: "נותקת מהמערכת, אנא התחבר בשנית",
  choose: "בחר",
  choose_file: "בחר קובץ",
  understood: "הבנתי",
  copy: "הועתק",
  whatOS: "איזו מערכת הפעלה:",
  somethingWentWrong: "משהו השתבש. אנא נסה/י שנית",
  instructionsForAppStore: "הוראות עבור חנות האפליקציות:",
};
export default Hebrew;
