import React from "react";
import "./CustomInput.css";

export default React.forwardRef(function CustomInput(props, ref) {
  return (
    <div dir={props.dir} className="custom-input">
      <input ref={ref} {...props} />
      <label>
        <p>{props.placeholder}</p>
      </label>
    </div>
  );
});
