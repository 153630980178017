import "./TopBar.css";
import LanguageSelector from "components/LanguageSelector";
import React from "react";
import strings from "assets/strings";
import SideMenu from "./SideMenu";
import axiosInstance from "services/axiosInstance";

export default function TopBar() {
  const logout = () => {
    sessionStorage.clear();
    axiosInstance.get("auth/logout").finally(() => window.location.reload());
  };

  return (
    <>
      <nav className="navbar navbar-dark bg-dark ">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/home">
            Crypto Scouter
          </a>
          <div className="leftBtn">
            <LanguageSelector></LanguageSelector>
            <button className="logoutBtn" onClick={logout}>
              {strings.logout}
            </button>
          </div>

          <img rel="preload" src="/images/lahav.png" className="mini-logo" />

          {<SideMenu {...{ logout }} />}
        </div>
      </nav>
    </>
  );
}
