import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import Screen from "components/Screen";
import { WebsocketProvider } from "hooks/websocketProvider";

export default function RouteWrapper() {
  return (
    <WebsocketProvider>
      <Screen>
        <Suspense
          fallback={
            <Loading loading={true} background="white" loaderColor="#eb882c" />
          }
        >
          <Outlet />
        </Suspense>
      </Screen>
    </WebsocketProvider>
  );
}
