import io from "socket.io-client";
function createWebsocket(accessToken) {
  return io(
    `${window.location.protocol == "https:" ? "wss" : "ws"}://${
      window.location.host
    }`,
    {
      transports: ["websocket"],
      maxHttpBufferSize: 1e8,
      auth: {
        token: `Bearer ${accessToken}`,
      },
      path: "/socket",
      reconnectionDelay: 10000, // defaults to 1000
      reconnectionDelayMax: 10000, // defaults to 5000
    }
  );
}

export default createWebsocket;
