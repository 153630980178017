import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "services/axiosInstance";
import Loading from "react-fullscreen-loading";
import Logo from "assets/images/IllSharpCod-max-1mb.gif";
import CustomButton from "components/CustomButton";
import LanguageSelector from "components/LanguageSelector";
import strings from "assets/strings";
import CustomInput from "components/CustomInput";

export default function ConfirmScreen() {
  const id = sessionStorage.getItem("id");
  const [loading, setLoad] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [err, setError] = useState(undefined);
  const inputReference = useRef(null);
  useEffect(() => {
    inputReference.current.focus();
  }, []);

  const onConfirmPressed = async (e) => {
    setLoad(true);
    e.preventDefault();
    try {
      const res = await axiosInstance.post(
        "auth/login",
        { id: parseInt(id), sms_token: userCode },
        { headers: { "content-type": "application/json; charset=utf-8" } }
      );
      localStorage.setItem("name", res.data.name);
      localStorage.setItem("showPrompt", true);
      sessionStorage.removeItem("id");
      window.location.reload();
    } catch (err) {
      console.log("err", { err });
      if (err.response.data) {
        setError(strings.code_error);
      } else {
        setError(strings.err_from_server);
      }
      setLoad(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loading loading={true} background="white" loaderColor="#eb882c" />
      ) : null}
      <div>
        <div className="selector">
          <LanguageSelector />
        </div>
        <form className="custom-form" onSubmit={onConfirmPressed}>
          <img src={Logo} className="logo-image" />
          <h3 className="header">CRYPTO SCOUTER</h3>
          <p>{strings.code}</p>
          <CustomInput
            type="number"
            ref={inputReference}
            className="form-control-confirm"
            placeholder={strings.code}
            pattern="[0-9]*"
            autoComplete="one-time-code"
            onChange={(e) => {
              setUserCode(e.target.value);
            }}
          />
          <CustomButton type="submit">{strings.confirm}</CustomButton>

          <div
            className={
              "alert border border-danger text-danger m-1  p-2 " +
              (err ? "d-block" : "d-none")
            }
            role="alert"
          >
            {err}
          </div>
        </form>
      </div>
    </>
  );
}
