import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import coinsReducer from "./coinsReducer";
import authReducer from "./authReducer";

const persistConfig = {
  key: "root",
  storage,
};

// const persistedCoinsReducer = persistReducer(persistConfig, coinsReducer);

export const store = configureStore({
  reducer: {
    auth: authReducer,
    // coins: coinsReducer,
    // coins: persistedCoinsReducer,
  },
  middleware: [thunk],
});

// export const persistor = persistStore(store);
