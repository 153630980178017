import React, { useEffect, useState } from "react";
import "./ScrollUpButton.css";
import { Icon } from "@iconify/react";

export default function ScrollUpButton({ scrollContainerRef }) {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!scrollContainerRef.current) return;

    const scrollTop = scrollContainerRef.current.scrollTop;

    setShowScroll(scrollTop > 100);
  };
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    scrollContainerRef.current.addEventListener("scroll", checkScrollTop);
    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", checkScrollTop);
    };
  }, [scrollContainerRef]);
  const scrollTop = () => {
    if (!scrollContainerRef.current) return;

    scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      id="scroll-up"
      className={`scroll-up ${showScroll ? "show" : ""}`}
      onClick={scrollTop}
    >
      <Icon
        icon="mdi:arrow-up-bold-box"
        width="70"
        height="70"
        cursor="pointer"
      />
    </div>
  );
}
