import { useState } from "react";
import Loading from "react-fullscreen-loading";
import Logo from "assets/images/IllSharpCod-max-1mb.gif";
import axios from "axios";
import CustomButton from "components/CustomButton";
import LanguageSelector from "components/LanguageSelector";
import strings from "assets/strings";
import ConfirmScreen from "pages/Confirm";
import "assets/styles/custom-form.css";
import { TextField } from "@mui/material";

export default function Login() {
  const [error, setError] = useState(undefined);
  const [loading, setLoad] = useState(false);
  const [sentSms, setSentSms] = useState(false);
  const [formData, setFormData] = useState({});
  function handleFormChange(e) {
    let data = formData;
    data[e.target.name] = e.target.value;
    setFormData(data);
    setError(undefined);
  }

  function validate() {
    const ID_REGEX = new RegExp("[0-9]{7}([0-9]{2})?");
    const PHONE_REGEX = new RegExp(
      "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
    );
    if (!PHONE_REGEX.test(formData.phone)) {
      return false;
    }
    if (!ID_REGEX.test(formData.id)) {
      return false;
    }

    return true;
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      setError(strings.incorrectDetails);
      return;
    }
    setLoad(true);

    try {
      const res = await axios.post(
        "/api/auth/sms",
        { ...formData, id: parseInt(formData.id) },
        {
          headers: { "content-type": "application/json; charset=utf-8" },
        }
      );
      sessionStorage.setItem("id", formData.id);
      setError(undefined);
      setSentSms(true);
    } catch (err) {
      if (err.response.status == 429) {
        setError(strings.tooManyRequests);
      } else {
        setError(strings.incorrectDetails);
      }
      console.log(err);
    }

    setLoad(false);
  };
  if (sentSms) return <ConfirmScreen />;
  return (
    <>
      <div>
        <div className="selector">
          <LanguageSelector />
        </div>
        {loading ? (
          <Loading loading={true} background="white" loaderColor="#eb882c" />
        ) : null}

        <form className="custom-form" onSubmit={handleOnSubmit}>
          <img className="logo-image" src={Logo} />
          <h3 className="header">CRYPTO SCOUTER</h3>
          <p>{strings.login}</p>
          <TextField
            margin="dense"
            name="id"
            size="small"
            type="number"
            required
            label={strings.id}
            onChange={handleFormChange}
            fullWidth
          />
          <TextField
            margin="dense"
            type="number"
            size="small"
            name="phone"
            required
            fullWidth
            onChange={handleFormChange}
            label={strings.phoneNumber}
          />
          <CustomButton disabled={error} type="submit" width={"300px"}>
            {strings.signin}
          </CustomButton>
          <div
            className={
              "alert border border-danger text-danger p-2 " +
              (error ? "d-block" : "d-none")
            }
            role="alert"
          >
            {error}
          </div>
        </form>
      </div>
    </>
  );
}
