const English = {
  incorrectDetails: "Incorrect Details",
  advancedOptions: "Advanced Options",
  coins: "Coins",
  noCoinsSelected: "No Coins Selected",

  cryptoSearch: "Crypto Search",
  notEnoughWords: "The number of words in the phrase is incorrect",
  results: "Results:",
  applications: "Applications Search",
  uploadAnImageFromTheAppStore: "Upload a screenshot from the app store.",
  applicationsDescription:
    "The software will detect if there are apps related to crypto (wallets, etc.)",
  goodQualityImage: "please make sure that the image is in good quality.",

  applicationsAndroid: `1. Open the "Google Play" app. \n
    2. Press on your profile picture in the upper corner. \n
    3. Press on "manage apps & device" \n
    4. Press on "manage" \n
    5. Take screenshots of the apps`,
  android: "Android",
  applicationsIphone: `1. Open the “App Store” app. \n
    2. Press on your profile picture in the upper corner. \n
    3. Scroll down, and take screenshots of the apps. \n
    `,
  iphone: "Iphone",
  filters: "filters",
  isTokens: "Token in use",
  tron: "Tron",
  identify: "id",
  type: "type",
  hello: "Hello,",
  remove: "Removed",
  search: "search",
  mail_title: "Encountered a problem? Want to give us feedback? Contact us!",
  successfully_send: "your message has been sent!",
  coin: "coin",
  time: "time",
  date: "date",
  dogecoin: "Dogecoin",
  remove_message: "user removed successfully",
  dont_have_history: "no history to display",
  plz_enter_id_for_remove:
    "please enter the id of the user you would like to remove",
  name_error: "Name cannot contain numbers or letters in English",
  more_info: "Info",
  hebrew_name: "Name in Hebrew without numbers",
  dont_show: "don't display again",
  id: "id",
  enter_id: "Enter id",
  error_id_phone: "id or phone are incorrect",
  phone_error: "invalid phone number",
  id_error: "incorrect id",
  has_details: "The details already exist",
  plz_enter_id: "Please enter the ID of the user you want to edit",
  move_main: "Got it",
  phoneNumber: "phone number",
  user_email: "email",
  mail: "mail",
  body_message: "The body of the message",
  send_mail: "Send Mail",
  signin: "sign in",
  confirm: "confirm",
  code: "verification code",
  name: "full name",
  unit: "unit",
  jobTitle: "role",
  create: "create accounts",
  register: "Create a New User",
  users: "Existing users",
  main: "main",
  info: "info",
  seed: "Mnemonic",
  incorrect_seed: "incorrect seed",
  str: "Text",
  access_level: "user level",
  seed_size: "Choose several words",
  password: "passphrase (optional)",
  check: "test",
  logout: "Logout",
  main_screen: "Main screen",
  choose_word: "Choose a word",
  search_word: "Search for a word",
  search_by_text: "Search by text",
  enter_word: "Enter text",
  what_is_seed: "What is a seed?",
  balance: "Balance",
  price: "price",
  transaction: "Transactions",
  history: "History",
  missing_words: "Details are missing",
  error: "Details are missing",
  incorrect: "Error in details",
  bitcoin: "Bitcoin",
  ethereum: "Etheruem",
  confirm_code: "Verification code sent successfully",
  hold_on: "Hold on!",
  exit_message: "Are you sure you want to exit the app?",
  cancel: "cancel",
  yes: "yes",
  update: "update",
  update_message:
    "An update to the application has been released. In order to use the application, you must reinstall it.",
  ok: "ok",
  edit: "edit",
  edit_user: "Edit User",
  delete: "Delete",
  in: "Accepting",
  get_info: "info:",
  nft_info: `הוא סוג מיוחד של פרוטוקול קריפטוגרפי, המייצג נכס ייחודי. בניגוד למטבעות מבוזרים כגון Bitcoin, NFT לא ניתן להחלפה באחר דומה לו ולכן מעצם טבעו אינו בר-חליפין.
  
  NFT מאפשר הוכחת מקוריותה של היצירה והבעלות עליה, אף שתחום האמנות הדיגיטלית מעצם טבעו מאפשר שכפול קל מהיר ומדויק של היצירה והפצתה ללא הרשאה ברשת האינטרנט
      `,
  tx_info: `העברה כספית (או מידע מסוג אחר) בין כתובת ארנק (של מטבע קריפטוגרפי) אחת לאחרת.
  לכל טרנזקציה יש מזהה ייחודי הנקרא TX ID או TX Hash שבאמצעותו אפשר לחפש מידע על אותה טרנזקציה בבלוקצ'יין.
  כל טרנזקציה מורכבת ממספר קלטים (כתובות ששלחו מטבעות) ומספר פלטים (כתובות שקיבלו מטבעות), כל אחד מהם בסכום שונה.
      
  דוגמה:
  בארנק מסוים ישנם 5 Bitcoin שמחולקים ל-2 כתובות, באחת מהן 3 Bitcoin ובשנייה 2.
  כאשר בעל הארנק יעביר לארנק אחר 4 Bitcoin הוא ישתמש בשתי הכתובות שברשותו כיוון שכל אחת מהן לבדה אינה מספיקה. במקרה זה יהיו לטרנזקציה 2 קלטים.
  כיוון שברשות השולח 5 Bitcoin והוא משלם רק 4, הוא יעביר את העודף, Bitcoin אחד, לכתובת אחרת שברשותו (בד"כ כתובת חדשה שהארנק שלו יוצר). במקרה זה יהיו 2 כתובות כפלט – כתובת של ארנק היעד שיקבל 4 Bitcoin והכתובת של השולח שתקבל קצת פחות מ-Bitcoin אחד.
  הסיבה שהעודף יהיה פחות מ-Bitcoin אחד היא שבכל טרנזקציה יש לשלם עמלה לכורים (אלו שמתחזקים את הבלוקצ'יין של Bitcoin), העמלה משולמת בשברירי Bitcoin (בדומה לאגורות מול השקל).`,
  pub_info: `מחרוזת ארוכה של אותיות ומספרים אשר מטרתה להוות אימות לבעלות על כתובת הארנק (ממפתח זה מייצרים את הכתובת). המפתח הציבורי והכתובת קשורים בצורה מתמטית באופן חד כיווני כך שניתן להגיע מהמפתח אל הכתובת (מפתח מסוים לעולם יהיה קשור לכתובת ספציפית), אך לא ניתן להגיע מהכתובת אל המפתח.
  מפתחות ציבוריים ב-Bitcoin מתחילות ב – 02, 03 ,04 ובתחיליות – x/y/z ולאחר מכן "pub".
   דוגמות למפתחות ציבוריים ב-Bitcoin:
  
  025918f4c2ec7bff521ae890ec3137a6c24e73a6c892cfc5d25bca318b79845ea3
  
  xpub6DSYTridGrheYW3HefNxybntJ8tWhrhQw1hFANMULTcQvdv9edTB6MGwJhU511hFZ4MpwLydmtLsNLewA5TQX8kpe3nrA2CmRuSrdscXxyh
  
  מפתחות ציבוריים ב-Ethereum מתחילים ב – 0x"" וארוכים  יותר מהפרטיים. דוגמות למפתח ב-Ethereum:
  
  0x03186872e6c5becc7d662004a0d01988965301fda9f20835f5bcf0647a20ad586e`,
  prv_info: `מחרוזת ארוכה של אותיות ומספרים המהווה את הקשר המתמטי בין ארנק למטבעות שמוחזקים בו (ממנו מייצרים מפתחות ציבוריים ומהם כתובות ש"מחזיקות" את יתרת המטבעות). המפתח הפרטי הוא סוג של סיסמת הגישה למטבעות, לכן למי שיש את המפתח הפרטי, יש גם את הגישה למטבעות והנכסים המקושרים לארנק.
  מפתחות פרטיים של Bitcoin יכולים להתחיל רק ב – 0 או 1, 1-9,
  ב0-9 וA-F,5H ,5J ,5K , K, S, 6P,  L
  ובתחיליות – x/y/z ולאחר מכן "prv".
  דוגמות למפתחות Bitcoin:
  
  Kwr8SDrK11ZSinbKw8jdcvQrXD1QCEKozRM2RVFkzjafcekQzP8X
  
  L517nqj87RM4vF2YjfQdHmMCKDkpYAviu7JBa2dPDVfeXBYMijkE
  
  S6c56bnXQiBjk9mqSYE7ykVQ7NzrRy
  
  xprv9yYFwFoG2xnPGLPbZS3nUJyV5a8jgz8ZCXMjNHnFPZKKqknL2MjLAoZPyLsX2SiUsWyab5gQ8Dbc2t7tdDFqnkwh63aBCJLuKLt9BPeSYPz
  
  zprvAWgYBBk7JR8GkC7Pytp2LdKXWSrnK5az7mkyKvr9onUq3MvwL9f3yn1XJrRvPxrvGQKohjxWFMnxfy42WdwNiWKo9gXnz247tyCECXJpyhx
  
  yprvALJHJuw1PJoGoKbnGH9s2nWwuYiJsaJ6JWCT6Qy91Fx6GqiMwpsL5P12rfErVaUKZg7sWcAHc2GxdhtShvsPb1X7w4G1ivm1vaizfeVbmHD
   
  מפתחות פרטיים בEthereum- מתחילים ב – 0x"". דוגמה למפתח ב-Ethereum:
  
  0xfbf687f190d9370727fa7e28558b6794019325d7c3747cacddaae179d4147053`,
  wallet_info: `הכתובת משמשת לשליחה או קבלה של עסקאות ברשת. הכתובת מוצגת כמחרוזת תווים משולבים של אותיות ומספרים. הארנק מקושר למפתחות אשר מעניקים גישה אל המטבעות הקריפטוגרפים. כתובות Bitcoin יכולות להתחיל אך ורק בתווים 3/1/bc1. דוגמה לכתובות Bitcoin:
  
  1EJ1U2eKSgZGSz6yVmksZFczAnK8F6MA7V
  
  bc1q4cc4xuyslvg6f4y7g6naewe942ne09d55y4dm2
  
  3JauUmvzLpFsLcch46dgRzVTXPEAMxU8BM
  
  כתובות Ethereum יתחילו רק ב- 0x, דוגמה לכתובת Ethereum:
  
  0xa8A3994114236c6f078A79D2615679cA05B52058`,
  wait: "Attention",
  blockchain_info: `שרשרת המורכבת מבלוקים ושייכת למטבע ספציפי (ישנם סוגי מטבעות ונכסים, לדוגמה NFT, אשר נמצאים כולם על בלוקצ'יין יחיד כגון הבלוקצ'יין של המטבע Ethereum). שרשרת בלוקים זו שקולה ליומן חשבונאי כאשר כל בלוק הוא דף ביומן ומכיל את רישומי ההעברות שקרו ב-10 הדקות שקדמו לחתימתו.`,
  warnings: `1. The information in the application is sensitive and should not be misused - the information should not be transferred to another party that is not authorized to view it, the information should not be saved on the cell phone and virtual currencies should not be transferred.
    2. The information is saved in the application for 24 hours so that you can use it when writing reports after performing the operation. After that the information will be deleted and you will have to perform a new search.`,
  out: "Sending",
  mnemonic_info: `רשימת מילים באורך של 12/24/18 מתוך מילון עם מילים נבחרות, רשימת מילים זאת נקראת גם מילות השחזור לארנק. (לעיתים נקראת בטעות "סיד", למרות שמונח זה מתאר מחרוזת אחרת - המחרוזת המופקת ממילים אלו). 
  
  במילים אלה טמון הארנק כולו, מהן ניתן לייצר את כל המפתחות (פרטיים וציבוריים) והכתובות של אותו ארנק, ובכך להעניק גישה מלאה לארנק ואפשרות להעברת המטבעות ממנו. סדדר המילים חשוב ליצירת הארנק, הזנת המילים בסדר שגוי יגרום ליצירת ארנק אחר.`,
  pass_info: `מחרוזת לבחירת בעל הארנק (כאשר יוצר את הארנק) המשמשת כשכבת אבטחה אופציונאלית (סוג של סיסמה אישית) בנוסף למילות השחזור(Mnemonic).
  אם בעל הארנק אכן הוסיף סיסמה כזאת על מילות השחזור, ייווצר ארנק אחר לגמרי.
      `,
  address: "address",
  value: "amount",
  fee: "sum",
  users_mess: "No users found",
  from: "Receiving address",
  to: "Sending address",
  err: "error",
  err_from_server:
    "An error occurred, please try again later. If the problem persists, contact the technology department in the national CyberCrime ​​Unit, Lahav 433",
  err_id: "ID must be 9 digits",
  successfully_created: "User successfully created",
  successfully_created_message:
    "Now the user can download the app to his phone and connect",
  first_message: `Welcome to the Crypto-Scouter!
    This tool is intended for use when performing a search in the field and aims to enable receiving real-time information about cryptocurrencies.
    You can enter a text string that will be found in the field even if you are not sure that it is related to cryptocurrencies.
    The received answer will indicate whether the string is indeed related to the coins, and if so, what it symbolizes, the addresses derived from it and the balance of the coins and their transaction history.`,
  us: `Developed by the
    Technologies department 
   of the National CyberCrime
    unit of Lahav 433 `,
  address: "address",
  transaction_id: "transaction id (hash)",
  date: "date",
  amount: "amount",
  code_error: "Incorrect code",
  cropImage: "Crop Imgae",
  uploadFile: "Choose file",
  completed: "Completed",
  processing: "Processing",

  reports: "Reports",
  entrancesReport: "Entrances",
  sameReport: "Same Searches",
  usersReports: "Users",
  searchsReports: "Searches",
  noResult: "Results not found",
  supportedCoin: "The app supports Bitcoin, Ethereum, Tron and Dogecoin",
  tokens: "Tokens",
  tooManyRequests: "Too Many Requests",
  login: "Login",
  menu: "Menu",
  settings: "Settings",
  chooseService: "Please select the requested service",
  needHelp: "Need help?",
  count_words: "Words amount: ",
  filterBy: "Filter by",
  all: "All",
  move_to_home: "Home Page",
  move_to_login: "Go to the login page",
  login_time_over: "Disconnected from the system, please log in again",
  choose: "choose",
  choose_file: "Choose file",
  understood: "understood",
  copy: "Copied",
  whatOS: "Choose OS:",
  somethingWentWrong: "Something went wrong. please try again",
  instructionsForAppStore: "Instructions for app stores:",
};
export default English;
