//import liraries
import React from "react";
import CustomButton from "components/CustomButton";
import "./Prompt.css";
import TopBar from "components/TopBar";
import strings from "assets/strings";
export default function Prompt() {
  const name = localStorage.getItem("name");

  return (
    <>
      <TopBar />
      <div className="Prompt">
        <h1 className="title">
          {strings.hello} {name}
        </h1>
        <h5>{strings.first_message}</h5>
        <h5>{strings.supportedCoin}</h5>
        <h6>{strings.us}</h6>
        <div className="warnings">
          <h1 className="text-red font-weight-bold">{strings.wait}</h1>
          <h1 className="mini_text text-red">{strings.warnings}</h1>
        </div>
        <CustomButton
          onClick={() => {
            localStorage.setItem("showPrompt", false);
            window.location.reload();
          }}
        >
          {strings.move_main}
        </CustomButton>
      </div>
    </>
  );
}
