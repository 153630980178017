import axiosInstance from "../services/axiosInstance";
import axios from "axios";
import { setToken, setAccessLevel, setConnected } from "store/authReducer";
const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

const setUpInterceptor = (store) => {
  const refreshToken = async () => {
    const res = await axios.get("/api/auth/refresh");
    store.dispatch(setToken(res.data.accessToken));
    store.dispatch(setAccessLevel(res.data.accessLevel));
    store.dispatch(setConnected(true));
  };

  const handleError = async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        await refreshToken();
        return axiosInstance(originalRequest);
      } catch (err) {
        return Promise.reject(error);
      }
    }
    if (error.response.status === 500) {
      localStorage.setItem("lastKnownError", JSON.stringify(error));
      window.location = "/unexpectedError";
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(async (config) => {
    config.headers = {
      Authorization: `Bearer ${store.getState().auth.accessToken}`,
      Accept: "application/json",
      "content-type": "application/json; charset=utf-8",
    };
    if (
      !isAbsoluteURLRegex.test(config.url) &&
      !config.url.startsWith("/api")
    ) {
      config.url = "/api/" + config.url;
    }
    return config;
  });

  axiosInstance.interceptors.response.use((response) => response, handleError);
};

export default setUpInterceptor;
