import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const coinsReducer = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setCoinMessage: (state, action) => {
      return {
        ...state,
        [action.payload.coin]: {
          message: action.payload.message,
          data: undefined,
        },
      };
    },
    setCoinData: (state, action) => ({
      ...state,
      [action.payload.coin]: { data: action.payload.data },
    }),
    reset: (state) => initialState,
  },
});

export const { setCoinMessage, setCoinData, reset } = coinsReducer.actions;

export default coinsReducer.reducer;
