import ErrorMessage from "components/ErrorMessage";
import strings from "assets/strings";
import CustomButton from "components/CustomButton";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.css";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error-page">
      <ErrorMessage text={strings.err_from_server} />
      <br />
      <CustomButton
        width={"100px"}
        onClick={() => {
          navigate("/login");
        }}
      >
        {strings.move_to_home}
      </CustomButton>
    </div>
  );
};
export default ErrorPage;
