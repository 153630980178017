import { useEffect } from "react";
import "./LanguageSelector.css";
export default function LanguageSelector() {
  useEffect(() => {
    const r = document.querySelector(":root");
    const lang = localStorage.getItem("language") || "Hebrew";

    r.style.setProperty(
      "--app-language-direction",
      lang === "Hebrew" ? "rtl" : "ltr"
    );
  }, []);

  return (
    <>
      <select
        defaultValue={localStorage.getItem("language") || "Hebrew"}
        className="selectpicker"
        data-width="fit"
        onChange={(e) => {
          localStorage.setItem("language", e.target.value);

          window.location.reload();
        }}
      >
        <option>English</option>
        <option value="Hebrew">עברית</option>
      </select>
    </>
  );
}
