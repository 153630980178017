import { Outlet, Navigate, Await } from "react-router-dom";
import { Suspense } from "react";
import Loading from "react-fullscreen-loading";
import Login from "pages/Login";
import Prompt from "pages/Prompt";
import axiosInstance from "services/axiosInstance";
import refreshToken from "utils/refreshToken";
import { useSelector } from "react-redux";

function ProtectedRoutes(connected) {
  return (
    <Suspense
      fallback={
        <Loading loading={true} background="white" loaderColor="#eb882c" />
      }
    >
      <Await
        resolve={refreshToken()}
        errorElement={<Navigate to="unexpectedError" />}
        children={(statusCode) => {
          if (statusCode === 401) {
            return <Login />;
          }
          if (localStorage.getItem("showPrompt") === "true") {
            return <Prompt />;
          }
          return <Outlet />;
        }}
      />
    </Suspense>
  );
}

function AdminRoutes() {
  const accessLevel = useSelector((state) => state.auth.accessLevel);
  console.log({ accessLevel });
  if (accessLevel !== 0 && accessLevel !== 1) return <Navigate to="/home" />;
  return <Outlet />;
}

function SuperAdminRoutes() {
  const accessLevel = useSelector((state) => state.auth.accessLevel);

  if (accessLevel !== 0) return <Navigate to="/home" />;
  return <Outlet />;
}

export { ProtectedRoutes, AdminRoutes, SuperAdminRoutes };
