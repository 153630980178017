import './ErrorMessage.css'

export default function ErrorMessage({ text }) {
  return (
    <div
      className={"alert border border-danger text-danger m-1 ErrorMessage p-2 "}
      role="alert"
    >
      {text}
    </div>
  );
}
