import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  accessLevel: null,
  connected: null,
};

const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      return {
        ...state,
        accessToken: action.payload,
      };
    },
    setAccessLevel: (state, action) => {
      return {
        ...state,
        accessLevel: action.payload,
      };
    },
    setConnected: (state, action) => {
      return {
        ...state,
        connected: action.payload,
      };
    },

    reset: (state) => initialState,
  },
});

export const { setToken, setAccessLevel, setConnected, reset } =
  authReducer.actions;

export default authReducer.reducer;
