import { useLocation, useNavigate } from "react-router-dom";
import "./SideMenuItem.css";
export default function SideMenuItem({ text, href }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <li>
        <a
          className={`dropdown-item ${
            currentPath === href ? "activeDropDowm" : ""
          }`}
          href=""
          onClick={(e) => {
            e.preventDefault();
            navigate(href);
            document.getElementById("offcanvas-close-button").click();
          }}
        >
          {text}
        </a>
      </li>
    </>
  );
}
