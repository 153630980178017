import React, { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  ProtectedRoutes,
  AdminRoutes,
  SuperAdminRoutes,
} from "./components/ProtectedRoutes";
import ErrorPage from "pages/ErrorPage";
import RouteWrapper from "components/RouteWrapper";
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const Reports = lazy(() => import("pages/Reports"));
const Home = lazy(() => import("pages/Home"));
const Info = lazy(() => import("pages/Info"));
const CreateUser = lazy(() => import("pages/CreateUser"));
const Mnemonic = lazy(() => import("pages/Mnemonic"));
const CryptoSearch = lazy(() => import("pages/CryptoSearch"));
const Users = lazy(() => import("pages/Users"));
const Balance = lazy(() => import("pages/Balance"));
const TxInfo = lazy(() => import("pages/Transactions"));
const History = lazy(() => import("pages/History"));
const EditUser = lazy(() => import("pages/EditUser"));
const Text = lazy(() => import("pages/Text"));
const Applications = lazy(() => import("pages/Applications"));

const theme = createTheme({
  direction: "ltr",
  palette: {
    primary: {
      main: "#eb882c",
    },
  },
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ltrCache = createCache({
  key: "mui",
});

export default function App() {
  return (
    <CacheProvider
      value={
        localStorage.getItem("language") === "English" ? ltrCache : cacheRtl
      }
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route element={<RouteWrapper />}>
                <Route path="/mnemonic" element={<Mnemonic />}></Route>
                <Route path="/cryptoSearch" element={<CryptoSearch />}></Route>
                <Route path="/history" element={<History />}></Route>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/edit" element={<EditUser />}></Route>
                <Route path="/search/:searchId" element={<Balance />}></Route>
                <Route path="/search/:searchId/:coin" element={<TxInfo />} />
                <Route path="/info" element={<Info />}></Route>
                <Route path="/text" element={<Text />}></Route>
                <Route path="/applications" element={<Applications />}></Route>
                <Route path="/errorPage" element={<ErrorPage />}></Route>
                <Route element={<SuperAdminRoutes />}>
                  <Route path="/reports" element={<Reports />}></Route>
                </Route>
                <Route element={<AdminRoutes />}>
                  <Route path="/users" element={<Users />}></Route>
                  <Route path="/create-user" element={<CreateUser />}></Route>
                </Route>
              </Route>
            </Route>

            <Route path="unexpectedError" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
}
