import { createContext, useEffect, useRef, useState } from "react";
import createWebsocket from "helpers/createWebsocket";
import { useSelector } from "react-redux";

export const WebsocketContext = createContext();

export const WebsocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const ws = useRef(null);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    if (!accessToken) {
      return;
    }
    const socket = createWebsocket(accessToken);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    ws.current = socket;
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.close();
    };
  }, []);

  return (
    <WebsocketContext.Provider value={{ socket: ws.current, isConnected }}>
      {children}
    </WebsocketContext.Provider>
  );
};
