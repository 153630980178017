import strings from "assets/strings";
import LanguageSelector from "components/LanguageSelector";
import { useLocation, useNavigate } from "react-router-dom";
import SideMenuItem from "./SideMenuItem";
import "./SideMenu.css";
import { useRef } from "react";

export default function SideMenu({ logout }) {
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname;
  const routes = [
    "/cryptoSearch",
    "/home",
    "/applications",
    "/history",
    "/info",
    "/reports",
  ];
  return (
    <div
      className="SideMenu offcanvas offcanvas-end text-bg-dark"
      tabIndex="-1"
      id="offcanvasDarkNavbar"
      aria-labelledby="offcanvasDarkNavbarLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
          {strings.menu}
        </h5>
        <button
          type="button"
          id="offcanvas-close-button"
          className="btn-close btn-close-white"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>

      <div className="offcanvas-body">
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li
            className={`nav-item ${
              routes.includes(currentPath) ? "active" : ""
            }`}
          >
            <a className="nav-link dropdown">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("home");
                  document.getElementById("offcanvas-close-button").click();
                }}
              >
                {strings.main_screen}
              </div>
            </a>

            <ul className=" dropDown dropdown-menu-dark ">
              <SideMenuItem
                href={"/cryptoSearch"}
                text={strings.cryptoSearch}
              />
              <SideMenuItem
                href={"/applications"}
                text={strings.applications}
              />
              <SideMenuItem href={"/history"} text={strings.history} />
              <SideMenuItem href={"/info"} text={strings.info} />

              {sessionStorage.getItem("level") == 0 ? (
                <SideMenuItem href={"/reports"} text={strings.reports} />
              ) : null}
            </ul>
          </li>

          <li>
            <hr className="divider" />
          </li>
          <li
            className={`nav-item ${
              currentPath === "/create-user" || currentPath === "/users"
                ? "active"
                : ""
            }`}
          >
            <a className="nav-link dropdown">{strings.settings}</a>

            <ul className="dropDown dropdown-menu-dark">
              <SideMenuItem href={"/create-user"} text={strings.create} />
              <SideMenuItem href={"/users"} text={strings.users} />
            </ul>
          </li>

          <div className="forPhones">
            <li>
              <hr className="divider" />
            </li>
            <li>
              <LanguageSelector></LanguageSelector>
            </li>
            <li>
              <hr className="divider" />
            </li>
            <li>
              <button className="logoutBtn" onClick={logout}>
                {strings.logout}
              </button>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}
