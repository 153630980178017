import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default axiosInstance;

// export default axiosApiInstance;
