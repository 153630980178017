import TopBar from "components/TopBar";
import "./Screen.css";
import { useRef } from "react";
import ScrollUpButton from "components/ScrollUpButton";
export default function Screen({ children }) {
  const screenRef = useRef(null);

  return (
    <>
      <ScrollUpButton scrollContainerRef={screenRef} />
      <TopBar></TopBar>
      <div ref={screenRef} className="screen">
        <div className="screen-content">{children}</div>
      </div>
    </>
  );
}
